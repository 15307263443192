.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.PhoneInputCountry{
  display:none !important;
}
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}


.list-group-item{
  padding:10px;
  margin:10px;
}


.action-row{
  margin-top:10px;
  margin-bottom:10px;
  padding:10px;
}



.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
.logo2{
background-image: url('./logo.jpg');
width: 2rem;
height: 2.25rem;
background-repeat: no-repeat;
background-size: cover;
}

.logo3{
  margin:auto;
  background-image: url('./logo.jpg');
  width: 5rem;
  height: 5.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  }

  .logo4{
    margin:auto;
    background-image: url('./logo.jpg');
    width: 3rem;
    height: 3.25rem;
    background-repeat: no-repeat;
    background-size: cover;
    }

.submit-form {
  max-width: 300px;
  margin: auto;
  margin-bottom:10px;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}


.list-group-two{
  max-height: 300px;
  text-align: left;
  margin-bottom: 10px;
  margin-left:-40px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}

.list-group-item:hover{
  cursor:pointer
}
